import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
  description: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Đorđe Andrić`,
    job: "CEO, SEO - Mozak operacije",
    description:
      "Na svaku nedoumicu hvata se za Google pretragu, nije ni čudo što voli SEO. Ipak, Fejsbuk mu je jača strana, zovemo ga Andrić - Reklama",
    avatar: "slike/oNama/Djordje-Andric.png",
  },
  {
    id: "4",
    name: `Dejan Andrić`,
    job: "Product Owner",
    description:
      "Osmeh i kreativa, to mu je suština. Dobro... i brdoo piva. Svaki dan je pun dobrih ideja - to naš Deja(n)",
    avatar: "slike/oNama/Dejan-Andric.png",
  },
  {
    id: "3",
    name: `Ana Vučković`,
    job: "Dizajnerka",
    description:
      "Vesela, a ozbiljna. Čik probaj da probiješ rok! Oko sokolovo, voli detalje, a mi ne volimo zato što obožava more, pa nas bude manje!",
    avatar: "slike/oNama/Ana-Vuckovic.png",
  },
  {
    id: "2",
    name: `Mihailo Marković`,
    job: "Fotograf i Videograf",
    description:
      "Voli da se smeje i stalno da nas škljoca, aparat i kamera - njegova dva verna pratioca.",
    avatar: "slike/oNama/Mihailo-Markovic.png",
  },
  {
    id: "7",
    name: `Ivan Milićevic`,
    job: "Software Engineer - IT Consultant",
    description:
      "Programer koji pored koda ume da uziva i u drugim stvarima, on i Djorđe nisu blizanci ali imaju isti ukus za majice :) ",
    avatar: "slike/oNama/Ivan-Milicevic.jpg",
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading desc="Nekad srećni, nekad tužni, ali uvek sa žarom u očima i svojim projektima u rukama! Evo prilike da nas bolje upoznate.">
        👨🏻‍👩🏻‍👦🏻‍👦🏻 Ovo Smo Mi
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base  font-semibold dark:text-neutral-400">
              {item.job}
            </span>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;