import React, { FC } from "react";
import "react-dates/initialize";
import StaySearchForm from "./StaySearchForm";

export type SearchTab = "Stays";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Stays";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = "" }) => {
  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        <li
          className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400 
`}
        >
          <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />

          <span>Izbaberi uslugu</span>
        </li>
      </ul>
    );
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      <StaySearchForm />
    </div>
  );
};

export default HeroSearchForm;