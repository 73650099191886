import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import {
  AdjustmentsIcon,
  CodeIcon,
  HashtagIcon,
  PresentationChartLineIcon,
  TrendingUpIcon,
} from "@heroicons/react/solid";
import ButtonSubmit from "./ButtonSubmit";

export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = "klikni da pogledaš usluge",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue);

  const [showPopover, setShowPopover] = useState(autoFocus);
  const [path, setPath] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    setValue(item);
    // @ts-ignore

    onInputDone && onInputDone(item);
    setShowPopover(false);
    switch (item) {
      case "Marketing na društvenim mrežama":
        return setPath("/marketing-na-drustvenim-mrezama");

      case "Izrada sajtova":
        return setPath("/izrada-sajtova");
      case "SEO Optimizacija":
        return setPath("/seo-optimizacija");
      case "Marketing Usluge":
        return setPath("/kompletne-marketing-usluge");
      default:
        return setPath("/");
    }
  };

  const renderSearchValue = () => {
    return (
      <>
        {[
          {
            text: "Marketing na društvenim mrežama",
            icon: <HashtagIcon className="w-8 h-8" />,
          },
          { text: "Izrada sajtova", icon: <CodeIcon className="w-8 h-8" /> },
          {
            text: "SEO Optimizacija",
            icon: <TrendingUpIcon className="w-8 h-8" />,
          },
          {
            text: "Marketing Usluge",
            icon: <PresentationChartLineIcon className="w-8 h-8" />,
          },
        ].map((item) => (
          <span
            onClick={() => handleSelectLocation(item.text)}
            key={item.text}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">{item.icon}</span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.text}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={`relative flex ${className}`} ref={containerRef}>
        <div
          onClick={() => setShowPopover(true)}
          className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
            showPopover ? "nc-hero-field-focused" : ""
          }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <AdjustmentsIcon className="w-8 h-8" />
          </div>
          <div className="flex-grow">
            <span
              className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            >
              {value}
            </span>
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              <span className="line-clamp-1">
                {!!value ? placeHolder : desc}
              </span>
            </span>
            {value && showPopover && (
              <ClearDataButton onClick={() => setValue("")} />
            )}
          </div>
        </div>
        {showPopover && (
          <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            {renderSearchValue()}
          </div>
        )}
      </div>
      <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
        <ButtonSubmit path={path} />
      </div>
    </>
  );
};

export default LocationInput;
