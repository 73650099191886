import rightImg from "images/about-hero-right.png";
import React, { FC, Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Dialog, Transition } from "@headlessui/react";
import SectionHero1Service from "./SectionHero1Service";
import { PortfolioDataType, PostDataType } from "../../data/types";
import NcImage from "../../shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import Badge from "../../shared/Badge/Badge";
import { DEMO_POSTS } from "../../data/posts";
import ButtonClose from "../../shared/ButtonClose/ButtonClose";
import ModalPortfolio from "../../components/Modal/ModalPortfolio";
import __posts from "../../data/jsons/__portfolio.json";

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];
export interface PageAboutProps {
  className?: string;
}

const PagePorfolio: FC<PageAboutProps> = ({ className = "" }) => {
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [selectedPost, setSelectedPost] = useState<PortfolioDataType>();
  console.log("selectedPost", __posts);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
    setSelectedPost(undefined);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  function handleSelect(id: string | number) {
    const post = __posts.find((post) => post.id === id);
    setSelectedPost(post as PortfolioDataType);
    openModalAmenities();
  }

  console.log(selectedPost);
  const renderPostRelated = (post: PortfolioDataType) => {
    return (
      <div
        key={post.id}
        onClick={() => handleSelect(post.id)}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group cursor-pointer"
      >
        <div />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />

        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name={post.badge} />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title} </span>
          </h2>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Naš Portfolio - Zadovoljni Klijenti Adjusment Digital </title>
        <meta
          name="description"
          content="Pogledajte ko su sve naši klijenti - Adjustment Digital agencije"
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.com/web-design"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Naš"
          heading2="Portfolio"
          btnText=""
          subHeading="Ovo su samo neki od projekata na koje smo PONOSNI. Sa velikom većinom i dalje sarađujemo."
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />
        <div className="relative  pb-12 lg:pb-24 -mt-20">
          <div className=" ">
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
              {/*  */}
              {__posts.map(renderPostRelated)}
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      <ModalPortfolio
        post={selectedPost}
        onClose={closeModalAmenities}
        isOpen={isOpenModalAmenities}
        uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
      />
    </div>
  );
};

export default PagePorfolio;
