import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface BtnProps {
  path: string;
}

const ButtonSubmit: FC<BtnProps> = ({ path }) => {
  return (
    <Link
      to={path}
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-[#4F46E5] hover:bg-indigo-700 flex items-center justify-center text-neutral-50 focus:outline-none"
    >
      <span className="mr-3 md:hidden">Pogledaj</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 "
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Link>
  );
};

export default ButtonSubmit;