import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionStatistic from "../PageAbout/SectionStatistic";
import SectionHeroService from "./SectionHeroService";
import SectionHero1Service from "./SectionHero1Service";
import SectionOurFeaturesService from "./SectionOurFeaturesService";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";
import animation from "../../images/animations/3-tiktok.json";

export interface PageAboutProps {
  className?: string;
}

const PageTikTok: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>TikTok</title>
        <meta
          name="description"
          content="Čim si na ovoj stranici, verujem da si svestan značaja TikToka trenutno. Napravićemo viralne TikTokove za tvoj biznis."
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.rs/tiktok-usluge"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Tiktok"
          heading2="Usluge"
          btnText=""
          subHeading={`${"Čim si na ovoj stranici, verujem da si svestan značaja TikToka trenutno. Napravićemo viralne TikTokove za tvoj biznis."}`}
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />
        <SectionHeroService
          rightImg="../../../slike/marketing-usluge.png"
          json={animation}
          heading="📚 Šta će mi TikTok?"
          btnText=""
          subHeading="Na TikToku gotovo besplatno možete postići ono za šta plaćate Facebook reklame! Kada se TikTok video ubaci u reklamu, postiže do 10 puta bolje rezultate!"
        />
        <SectionHowItWork
          title="Kako izgleda taj proces?"
          subtitle="Koje su faze saradnje, kako sve funkcioniše?"
          data={[
            {
              id: 1,
              img: "../../../slike/istrazivanje-proces.png",
              imgDark: "../../../slike/istrazivanje-proces.png",
              title: "Napravimo plan",
              desc: "Na početku, naravno, pravimo detaljan plan is cenarija za TikTokove.",
            },
            {
              id: 2,
              img: "../../../slike/planiranje-proces.png",
              imgDark: "../../../slike/planiranje-proces.png",
              title: "Radimo dogovoreno",
              desc: "Odmah krećemo sa realizacijom dogovorenog. Snimamo materijale.",
            },
            {
              id: 3,
              img: "../../../slike/kreiranje-proces.png",
              imgDark: "../../../slike/kreiranje-proces.png",
              title: "Dobiješ rezultate",
              desc: "Logičan sled događaja: vidljivi rezultati, svi zadovoljni i nastavljamo dalje saradnju!",
            },
          ]}
        />
        <SectionOurFeaturesService
          rightImg="../../../slike/marketing-usluge.png"
          btn="VIŠE PREGLEDA
"
          text="TikTok i Reels ti pomažu da dođeš do većeg broja pregleda potpuno besplatno! Do sada smo za svakog klijenta uspeli da napravimo VIRALNE TikTokove!
"
          btn2="BOLJI REZULTATI KAMPANJA
"
          btn3="RAZVOJ BRENDA"
          subtitle="Kako do više pregleda?"
          subtitle2="Poboljšaj trenutne kampanje
"
          subtitle3="Brži razvoj brenda.
"
          text2="Kada kroz TikTok i Reels ljudi čuju za vas, možemo im pustiti ponovo reklame koje će mnogo više prodaje doneti, jer su ljudi čuli za vas 'od nekog' na TikToku ili Instagramu.
"
          text3="Što više ljudi čuje za vas, to će vaš brend jačati. Na TikToku možete vrlo lako dođi do stotine hiljada pregleda..
"
          title="Šta sve postižem sa TikTokom?"
          title2="TIKTOK USLUGE
"
        />
        <SectionHeroService
          rightImg="../../../slike/klijenti-reels.png"
          heading="📹 Da li imate neke rezultate iza sebe?"
          btnText=""
          subHeading="Naravno! Svakom klijentu smo do sad doneli preko milion pregleda ukupno! Pogledajte TikTok i Instagram profile: @konovors , @naucidizajn , @biomedis.rs"
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj druge usluge"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageTikTok;
