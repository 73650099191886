import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
}

export const socialsLinks: SocialType[] = [
  {
    name: "Facebook",
    icon: "lab la-facebook-square",
    href: "https://fb.me/adjustmentdigital",
  },
  {
    name: "Linkedin",
    icon: "lab la-linkedin",
    href: "https://www.linkedin.com/company/adjustment-digital",
  },
  {
    name: "Youtube",
    icon: "lab la-youtube",
    href: "https://www.youtube.com/channel/UCFtUWTuqd8TwqOU6sZ8Mjaw",
  },
  {
    name: "Instagram",
    icon: "lab la-instagram",
    href: "https://instagram.com/adjustmentdigital",
  },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-4" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2  "
        key={index}
      >
        <i className={item.icon}></i>
        <span className=" lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div
      className={`nc-SocialsList1 ${className} items-start`}
      data-nc-id="SocialsList1"
    >
      <h2 className="font-semibold text-neutral-700 dark:text-neutral-200 ">
        Pratite nas
      </h2>
      {socialsLinks.map(renderItem)}
    </div>
  );
};

export default SocialsList1;