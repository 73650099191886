import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionStatistic from "../PageAbout/SectionStatistic";
import SectionHeroService from "./SectionHeroService";
import SectionHero1Service from "./SectionHero1Service";
import SectionOurFeaturesService from "./SectionOurFeaturesService";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import HIW1img from "../../images/HIW2-1.png";
import HIW1imgDark from "../../images/HIW2-1-dark.png";
import HIW2img from "../../images/HIW2-2.png";
import HIW2imgDark from "../../images/HIW2-2-dark.png";
import HIW3img from "../../images/HIW2-3.png";
import HIW3imgDark from "../../images/HIW2-3-dark.png";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";

export interface PageAboutProps {
  className?: string;
}

const PageService: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Booking React Template</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Modern & Creative"
          heading2="Booking online, Real Estate"
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />
        <SectionHeroService
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Smart search",
              desc: "Name the area or type of home you are looking for the search bar. Our app will find you the perfect match.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Choose property",
              desc: "From the number of options our app will provide, you can select any property that you like to explore.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Book you property",
              desc: "Find a home or space from our search bar. Enter your specific location, property type and price range.",
            },
          ]}
        />
        <SectionOurFeaturesService
          btn="prop"
          text="prop"
          btn2="prop"
          btn3="prop"
          subtitle="prop"
          subtitle2="prop"
          subtitle3="prop"
          text2="prop"
          text3="prop"
          title="prop"
        />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          // categories={DEMO_CATS_2}
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj druge usluge"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageService;