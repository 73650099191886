import React, { FC, ReactNode } from 'react';
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  heading2: string;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  heading,
  subHeading,
  heading2,
}) => {
  return (
    <div className={`container mx-auto pr-0 pl-0 md:pr-1 md:pl-1"`}>
      <div className="w-full pb-12">
        <div className="container  py-10 md:pt-28 mx-auto text-left md:max-w-none md:text-center pr-0 pl-0 md:pr-1 md:pl-1">
          <h1 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            {heading} <br />
            <span className="text-[#4F46E5] font-bold">{heading2}</span>
          </h1>
          <div className="mx-auto mt-5 text-gray-400 md:mt-12 md:max-w-lg md:text-center lg:text-lg text-left">
            {subHeading}
          </div>
        </div>
      </div>
      <BgGlassmorphism />
    </div>
  );
};

export default SectionHero;
