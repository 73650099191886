import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { TaxonomyType } from "data/types";
import { Helmet } from "react-helmet";

import HIW1img from "images/LogoKlijenataPocetna/nauci-dizajn-logo-white.png";
import HIW2img from "images/LogoKlijenataPocetna/enigma-bar-light.png";
import HIW3img from "images/LogoKlijenataPocetna/konovo-logo-white.png";
import HIW4img from "images/LogoKlijenataPocetna/strah-od-brade-logo-color.png";
import HIW6img from "images/LogoKlijenataPocetna/polovnilaptop-logo-white.png";
import HIW1imgDark from "images/LogoKlijenataPocetna/nauci-dizajn-white-logoo.png";
import HIW2imgDark from "images/LogoKlijenataPocetna/enigmaBarDark.png";
import HIW3imgDark from "images/LogoKlijenataPocetna/konovo-logo-dark.png";
import HIW4imgDark from "images/LogoKlijenataPocetna/strah-od-brade-logo-colorwhite.png";
import HIW5imgDark from "images/LogoKlijenataPocetna/beerkan-logo.png";
import HIW6imgDark from "images/LogoKlijenataPocetna/polovni-logo-black.png";
import rightImgPng from "images/s3.png";

import SectionDowloadApp from "./SectionDowloadApp";
import SectionClientSay from "../../components/SectionClientSay/SectionClientSay";
import SectionHero from "../../components/SectionHero/SectionHero";
import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";
export const SLIDER: TaxonomyType[] = [
  {
    id: "1",
    href: "/izrada-sajtova",
    name: "Izrada Sajtova",
    taxonomy: "category",
    desc: "Prezentuj svoj biznis online",
    thumbnail: "/slike/usluge-izrada-sajtova.png",
  },
  {
    id: "2",
    href: "/marketing-na-drustvenim-mrezama",
    name: "FB Oglašavanje",
    taxonomy: "category",
    desc: "Oglašavanje na dr. mrežama",
    thumbnail: "/slike/usluge-facebook-oglasavanje.png",
  },
  {
    id: "3",
    href: "/marketing-na-drustvenim-mrezama",
    name: "Vođenje Profila",
    taxonomy: "category",
    desc: "Održavaj kontakt sa pratiocima",
    thumbnail: "/slike/usluge-vodjenje-profila.png",
  },

  {
    id: "4",
    href: "/seo-optimizacija",
    name: "SEO Optimizacija",
    taxonomy: "category",
    count: 188288,
    desc: "Budi prvi na Google pretrazi",
    thumbnail: "/slike/usluge-seo.png",
  },
  {
    id: "5",
    href: "/kompletne-marketing-usluge",
    name: "Marketing Usluge",
    taxonomy: "category",

    count: 188288,
    desc: "Prepusti nam ceo marketing\n" + "Izbaciti konsultacije kao uslugu\n",
    thumbnail: "/slike/usluge-marketing-usluge.png",
  },
];
function PageHome2() {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Vaša Nova Marketing Agencija - Adjustment Digital</title>
        <meta
          name="description"
          content="Tvoj prijatelj ka postizanju digitalnih uspeha. Tim profesionalaca u svom poslu, posvećem tvom biznisu u potpunosti. Prijateljski, a ne agencijski pristup!"
        />
      </Helmet>
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}
      <BgGlassmorphism />
      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <SectionHero className="pt-10 lg:pt-20 " />

        {/* SECTION 1 */}
        <h2 className="text-3xl md:text-4xl font-semibold text-center dark:text-white">
          Zadovoljni klijenti
        </h2>
        <div className="ncSectionLogos grid grid-cols-2 lg:grid-cols-6 gap-5 sm:gap-16 !mt-18">
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW1img} alt="logo1" />
            <img className="hidden dark:block" src={HIW1imgDark} alt="logo1" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW2imgDark} alt="logo4" />
            <img className="hidden dark:block" src={HIW2img} alt="logo4" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW3imgDark} alt="logo2" />
            <img className="hidden dark:block" src={HIW3img} alt="logo2" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW4img} alt="logo3" />
            <img className="hidden dark:block" src={HIW4imgDark} alt="logo3" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW5imgDark} alt="logo3" />
            <img className="hidden dark:block" src={HIW5imgDark} alt="logo3" />
          </div>{" "}
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={HIW6imgDark} alt="logo3" />
            <img className="hidden dark:block" src={HIW6img} alt="logo3" />
          </div>
        </div>

        {/* SECTION */}
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: "../../../slike/istrazivanje-proces.png",
              imgDark: "../../../slike/istrazivanje-proces.png",
              title: "Analiza",
              desc: "Pre početka svake saradnje, analiziramo tržište, konkurenciju i koje ćemo komunikacione kanale koristiti. Pravimo detaljan plan kako ćemo doći do najboljih rezultata.",
            },
            {
              id: 2,
              img: "../../../slike/kreiranje-proces.png",
              imgDark: "../../../slike/kreiranje-proces.png",
              title: "Realizacija",
              desc: "Naš tim daje svoj maksimum da u što kraćem roku implementira dogovoreni plan. Pravimo kampanje, materijale, implementiramo tekst i mnogo drugih stvari.",
            },
            {
              id: 3,
              img: "../../../slike/pracenje-rezultata-proces.png",
              imgDark: "../../../slike/pracenje-rezultata-proces.png",
              title: "Rezultati",
              desc: "Nakon toga, pratimo postavljene ciljeve i poboljšavamo dok ne dođemo do željenih rezultata. Na kraju će svi živi da te pitaju: “Ej, ko ti radi marketing?”",
            },
          ]}
        />
        <SectionSliderNewCategories
          heading="Kako možemo da ti pomognemo?"
          subHeading="
Izaberi uslugu koja ti je potrebna i olakšaj sebi poslovanje "
          categoryCardType="card5"
          itemPerRow={5}
          categories={SLIDER}
          uniqueClassName="PageHome_s3"
          home={true}
        />
        {/* SECTION */}
        <SectionDowloadApp />
        {/*<ServicesComponent />*/}

        {/* SECTION2 */}
        <SectionOurFeatures type="type2" rightImg={rightImgPng} />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
        <SectionSubscribe2 />
      </div>
    </div>
  );
}

export default PageHome2;