import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import React from "react";
import appSvg2 from "images/appSvg2.png";

const SectionDowloadApp = () => {
  return (
    <div className="hidden lg:block">
      <div className="relative mb-6 inline-block  sm:hidden">
        <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold text-neutral-800">
          Vidljivi rezultati
        </h2>
        <span className="block mt-7 max-w-lg text-neutral-800">
          Rezultati koji postižemo su uvek transparentni i u svakom trenutku ćeš
          na raspolaganju imati rezultate koje smo postigli. Ne krijemo ništa i
          želimo da te uverimo u naš kvalitet.
        </span>
      </div>

      <div className="relative pb-0 pt-24 lg:py-32 xl:py-40 2xl:py-48">
        <BackgroundSection className="bg-neutral-100 bg-opacity-80 dark:bg-opacity-100 ">
          <img
            className="absolute inset-0 w-full h-full object-cover rounded-3xl object-right filter saturate-200 invert-0"
            src="../../../slike/vidljivi-rezultati.jpg"
            alt="dowloadAppPng"
          />
        </BackgroundSection>
        <div className="hidden sm:inline-block relative  ">
          <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold text-neutral-800">
            Vidljivi rezultati
          </h2>
          <span className="block mt-7 max-w-lg text-neutral-800">
            Rezultati koji postižemo su uvek transparentni i u svakom trenutku
            ćeš na raspolaganju imati rezultate koje smo postigli. Ne krijemo
            ništa i želimo da te uverimo u naš kvalitet.
          </span>

          <img
            className="hidden lg:block absolute lg:left-full lg:top-0 xl:top-1/2 z-10  lg:max-w-sm 2xl:max-w-none"
            src={appSvg2}
            alt=""
          />
        </div>
        <div className="block lg:hidden mt-24 max-w-2xl rounded-3xl overflow-hidden"></div>
      </div>
    </div>
  );
};

export default SectionDowloadApp;
