import React, { FC, useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Glide from "@glidejs/glide";
import NcImage from "shared/NcImage/NcImage";
import { PortfolioDataType } from "../../data/types";

export interface ModalPortfolioProps {
  post?: PortfolioDataType;
  onClose: () => void;
  isOpen: boolean;
  initFocus?: number;
  uniqueClassName: string;
}

const ModalPortfolio: FC<ModalPortfolioProps> = ({
  post,
  isOpen,
  onClose,
  initFocus = 0,
  uniqueClassName = "",
}) => {
  console.log("post", post);
  //random id
  const randomId = useMemo(() => Math.random().toString(36).substr(2, 9), []);

  const UNIQUE_CLASS = "glidejs" + randomId.replace(/:/g, "_");
  let completeButtonRef = useRef(null);

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      // @ts-ignore
      direction:
        document.querySelector("html")?.getAttribute("dir") === "rtl"
          ? "rtl"
          : "ltr",
      gap: 10,
      perView: 1,
      startAt: initFocus,
      autoplay: 5000,
    });
  }, [UNIQUE_CLASS, initFocus]);

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, isOpen, UNIQUE_CLASS]);

  const renderSlider = () => {
    return (
      <div
        className={`modalPhotos-single-gallery ${UNIQUE_CLASS} group relative flex flex-col z-50 w-full  max-h-[400px] md:max-h-[800px] md:mt-12 `}
      >
        {/*  */}

        <div
          className="controls_nav glide__bullets mb-4"
          data-glide-el="controls[nav]"
        >
          {post?.images?.map((_, index) => (
            <div key={index} className="text-center hidden text-sm">
              <span className="text-xl font-semibold"> {index + 1}</span>
              <span> / {post?.images.length}</span>
            </div>
          ))}
        </div>
        {/*  */}
        <div
          className="glide__track max-h-full h-full relative z-50"
          data-glide-el="track"
        >
          <ul className="glide__slides h-full ">
            {post?.images?.map((item, index) => (
              <li className="glide__slide relative h-full" key={index}>
                <NcImage
                  src={item}
                  containerClassName=" w-full h-full flex items-center justify-center "
                  className=" max-w-full max-h-full rounded-2xl"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderModalPortfolio = () => {
    return (
      <Dialog
        initialFocus={completeButtonRef}
        as="div"
        className="ProductDetailModalPortfolio fixed inset-0 z-50 overflow-y-auto dark bg-black/80 text-neutral-200 hiddenScrollbar"
        onClose={onClose}
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center flex">
          <Dialog.Overlay className="fixed inset-0 " />
          <div
            ref={completeButtonRef}
            className="absolute left-2 top-2 md:top-4 md:left-4 z-50"
          >
            <ButtonClose className=" sm:w-12 sm:h-12" onClick={onClose} />
          </div>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 justify-center items-center">
            {renderSlider()}
            <div className="z-max text-left md:ml-3 flex flex-col gap-3 text-sm -mt-16">
              <h2 className="text-center font-semibold text-xl">
                {post?.badge}
              </h2>

              <p>{post?.description}</p>
              <p>{post?.description2}</p>
              <p>{post?.description3}</p>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  return renderModalPortfolio();
};

export default ModalPortfolio;
