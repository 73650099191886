import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Naše lokacije",
    menus: [
      { href: "/kontakt", label: "Adjustment Digital LTD" },
      {
        href: "/kontakt",
        label:
          "71-75 SHELTON STREET, COVENT GARDEN, LONDON, WC2H 9JQ, UNITED KINGDOM",
      },
      { href: "/kontakt", label: "ĐORĐE ANDRIĆ PR" },
      { href: "/kontakt", label: "Zorina 31, 11147 Beograd, Srbija" },
      { href: "/kontakt", label: "office@adjustmentdigital.rs" },
      { href: "/kontakt", label: "+381 69 2017 933" },
    ],
  },
  {
    id: "1",
    title: "Usluge",
    menus: [
      {
        href: "marketing-na-drustvenim-mrezama",
        label: "Marketing na društvenim mrežama",
      },
      { href: "/izrada-sajtova", label: "Izrada sajtova" },
      { href: "/seo-optimizacija", label: "SEO Optimizacija" },
      {
        href: "/kompletne-marketing-usluge",
        label: "Kompletne marketing usluge",
      },
      {
        href: "/marketing-na-drustvenim-mrezama",
        label: "Vodjenje društvnih mreža",
      },
    ],
  },
  {
    id: "2",
    title: "Bitni Linkovi",
    menus: [
      { href: "/onama", label: "O nama" },
      { href: "/kontakt", label: "Kontakt" },
      { href: "#", label: "Uslovi korišćenja" },
      { href: "#", label: "Politika Privatnosti" },
      { href: "/blog", label: "Blog" },
    ],
  },
];
const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white "
                href={item.href}
              >
                <i className={`${item.icon} w-6 h-6 `}></i>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-32 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>

          <span className="text-sm hidden lg:block">
            Adjustment Digital je tvoj prijatelj ka postizanju digitalnih
            uspeha. Tim profesionalaca u svom poslu, posvećen tvom biznisu u
            potpunosti. Prijateljski, a ne agencijski pristup!
          </span>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <SocialsList1 className="flex lg:flex-row flex-col   lg:space-x-0 lg:flex-col space-y-4 " />
      </div>
    </div>
  );
};

export default Footer;
