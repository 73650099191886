import React, { FC } from "react";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { useSetRecoilState } from "recoil";
import { modalState } from "../../atoms/modalAtom";

export interface SectionSubscribe2Props {
  className?: string;
  openModalAmenities?: () => void;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const setModal = useSetRecoilState(modalState);
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Želiš da sarađujemo? 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Popuni informacije o preduzeću i zatraži ponudu. Vrlo brzo ćemo te
          kontaktirati sa poslovnim predlogom. Evo šta sve dobijaš saradnjom sa
          nama:
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Popust na dugoročnu saradnju
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Tim specijalizovanih stručnjaka
            </span>
          </li>{" "}
          <li className="flex items-center space-x-4">
            <Badge color="yellow" name="03" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Najnovije tehnike iz sveta marketinga
            </span>
          </li>{" "}
          <li className="flex items-center space-x-4">
            <Badge color="green" name="04" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Prijateljski i posvećen odnos
            </span>
          </li>{" "}
          <li className="flex items-center space-x-4">
            <Badge color="indigo" name="05" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Sve marketing usluge na jednom mestu
            </span>
          </li>
        </ul>
        <div className="py-4">
          <div>
            <ButtonPrimary
              type="submit"
              className="bg-[#4F46E5] hover:bg-indigo-700"
              onClick={() => setModal({ isOpen: true })}
            >
              Zatraži Ponudu
            </ButtonPrimary>
          </div>
        </div>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;