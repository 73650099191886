import rightImg from 'images/about-hero-right.png';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionStatistic from '../PageAbout/SectionStatistic';
import SectionHeroService from './SectionHeroService';
import SectionHero1Service from './SectionHero1Service';
import SectionOurFeaturesService from './SectionOurFeaturesService';
import SectionHowItWork from '../../components/SectionHowItWork/SectionHowItWork';

import SectionBecomeAnAuthor from '../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';

import SectionSliderNewCategories from '../../components/SectionSliderNewCategories/SectionSliderNewCategories';
import Reklamiranje from './Reklamiranje';
import { PricingItem } from '../PageSubcription/PageSubcription';

export interface PageAboutProps {
  className?: string;
}
const instagramPricing: PricingItem[] = [
  {
    isPopular: true,
    name: 'OSNOVNI SEO PAKET',
    pricing: '500€',
    per: '/mesečno',
    features: [
      'Optimizacija malih i srednjih sajtova',
      'SEO Analiza',
      'Struktura sajta',
      'On Page SEO',
      'Off Page SEO',
      'Istraživanje ključnih reči',
      'Analiza konkurencije',
      'Povezivanje Google alata',
    ],
    negativeFeatures: [
      'Implementacija struktuiranih podataka',
      'Građenje kvalitetnih linkova',
      'Optimizacija konverzija',
      'Izrada sadržaja i blogova',
    ],
    desc: `Dodatni budžet za građenje linkova nije uključen u cenu.`,
  },
  {
    isPopular: true,
    name: 'NAPREDNI SEO PAKET',
    pricing: '1000€',
    per: '/mesečno',
    features: [
      'Optimizacija malih i srednjih sajtova',
      'SEO Analiza',
      'Struktura sajta',
      'On Page SEO',
      'Off Page SEO',
      'Istraživanje ključnih reči',
      'Analiza konkurencije',
      'Povezivanje Google alata',
      'Implementacija struktuiranih podataka',
      'Građenje kvalitetnih linkova',
      'Optimizacija konverzija',
      'Izrada sadržaja i blogova',
    ],

    desc: `Dodatni budžet za građenje linkova nije uključen u cenu.`,
  },
];
const PageSEOptimizacija: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>SEO Optimizacija Sajtova - Budi Prvi Na Google Pretrazi</title>
        <meta
          name="description"
          content="Da li si nekada potražio nešto van prve strane Googla? Nismo ni mi, zato je SEO Optimizacija Sajtova najbolja drugoročna marketing strategija za povećanje prodaje."
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.rs/seo-optimizacija"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="SEO"
          heading2="Optimizacija
Sajtova
"
          btnText=""
          subHeading="Dnevno se obavi preko 7 milijardi pretraga na Google pretraživaču. Siguran sam da niste primetili da na telefonu nemate opciju da odete na drugu stranu Googla? Imate dugme More Results koje sigurno nikad u životu niste ni videli, a kamoli kliknuli. Mislim da vam je ovo dovoljno informacija da skapirate koliko je bitno pozicioniranje na Google. SEO Optimizacija služi upravo tome, da optimizuje vaš sajt tako iskočite među prvima."
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />

        <SectionHowItWork
          data={[
            {
              id: 1,
              img: '../../../slike/istrazivanje-proces.png',
              imgDark: '../../../slike/istrazivanje-proces.png',
              title: 'Analiza i istraživanje',
              desc: 'Istraživanje i kreiranje liste ključnih reči prema broju mesečnih pretraga, praćenje konkurencije.',
            },
            {
              id: 2,
              img: '../../../slike/planiranje-proces.png',
              imgDark: '../../../slike/planiranje-proces.png',
              title: 'Planiranje i saveti',
              desc: 'Kreiranje daljeg plana, osmišljavanje tema za blog. Ispravke samog izgleda sajta, saveti za poboljšanje, ubrzavanje sajta.',
            },
            {
              id: 3,
              img: '../../../slike/kreiranje-proces.png',
              imgDark: '../../../slike/kreiranje-proces.png',
              title: 'Optimizacija i podešavanje',
              desc: 'Praćenje i analiza urađenog, stalno ažuriranje i podešavanje jer SEO zahteva stalne izmene, ali i veliko veliko strpljenje. Ne očekujte uspeh preko noći!',
            },
            {
              id: 4,
              img: '../../../slike/pracenje-rezultata-proces.png',
              imgDark: '../../../slike/pracenje-rezultata-proces.png',
              title: 'Praćenje rezultata i čekanje',
              desc: 'Kreiranje naloga i podešavanje analitike, pixela i raznih pluginova za brži rad i učitavanje sajta. Optimizacija slika, tekstova i celog sajta.',
            },
          ]}
        />
        <SectionHeroService
          className="!mt-28"
          rightImg="../../slike/sta-je-seo-optimizacija.png"
          heading="🔎 Šta je SEO optimizacija?"
          btnText=""
          subHeading="Nije SEO optimizacija već samo SEO. Search Engine Optimization znači optimizacija sajta za pretraživače kao što je Google, Bing i drugi. SEO je skup aktivnosti koje se rade kako bi se optimizovale veb stranice radi povećanja poseta i prometa iz organskih rezultata pretraživača.
Prednosti su očigledne: besplatan, pasivan promet na vašem sajtu svakog meseca. Organske posete se mnogo više vrednuju od plaćenih i zato svi žele da optimizuju svoje sajtove. Nema ništa lepše nego kada neko ukuca neku reč, a vi iskočite u top 10 rezultata na Google.
Kako optimizovati sajt? SEO nije ni malo lak posao jer Google koristi preko 200 različitih faktora, kako bi rangirao vaš sajt. To nije samo brzina sajta i funkcionalnost, nego mnogo više od toga. „Gde kupiti“ i „Blizu mene“ pretrage na mobilnom telefonu su porasle za preko 200% u prethodne dve godine. Priznaj da si i ti nekad pretraživao slično!
"
        />
        <SectionOurFeaturesService
          btn="prop"
          text="Prvi korak u optimizaciji pretraživača je utvrđivanje za šta zapravo optimizujete sajt. To znači pronalaženje pojmova i reči koje ljudi pretražuju, takođe poznati kao „ključne reči“. Ključne reči su one reči za koje želite da se vaš sajt rangira na Google pretraživaču."
          btn2="prop"
          btn3="prop"
          subtitle="prop"
          subtitle2="prop"
          subtitle3="prop"
          text2="Na primer, možda ćete želeti da se vaša firma pojavi kada ljudi ukucaju „patike“, a možda i kada ukucaju stvari poput „patike cena“. To sve sve istraži, nađu se ključni pojmovi i onda se polako kroz tekst implementiraju na sajt."
          text3="prop"
          title="SEO - Optimizacija Sajta"
          styleType={true}
          title2="BUDI PRVI NA GOOGLE"
          rightImg="../../../slike/seo-optimizacija-sajta.png"
        />
        <Reklamiranje
          text="
Cena SEO usluga varira od dosta stvari. Za početak zavisi od veličine vašeg preduzeća. Zavisi od zasićenosti oblasti u kojoj poslujete, od veličine sajta i broja stranica na vašem sajtu. Da li želite sami da gradite linkove ili želite da to prepustite nama, da li želite da mi pišemo blogove ili imate svog pisca i tako dalje. Zato smo podelili na osnovni i napredni SEO paket. Osnovni je namenjen za manje konkurentne oblasti, dok je napredni za srednje i visoko konkurentne oblasti i velika preduzeća. Link building tj. građenje linkova zahteva dodatan budžet koji nije uračunat.
"
          title="💰SEO Optimizacija cena"
          type="type2"
          pricing={instagramPricing}
        />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor
            title="💸 Da li se SEO isplati?"
            text="Pokretanje i vođenje biznisa iziskuje dosta vremena i novca, kao i svakom ko vodi biznis, bitno je da nešto vraća novac na uloženo. Upravo je to glavni adut zbog kojeg klijenti nastavljaju saradnju sa nama. SEO je odlična stvar jer pravi dugoročne i prirodne rezultate kroz Google. Ne tera ljude ništa da kupuju, već će oni kojima je vaša usluga sami doći do vas!"
          />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj druge usluge"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageSEOptimizacija;
