import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADRESA",
    desc: "Zorina 31, 11147 Beograd",
  },
  {
    title: "💌 EMAIL",
    desc: "office@adjustmentdigital.rs",
  },
  {
    title: "☎ TELEFON",
    desc: "+381 69 2017 933",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .send(
        "service_dowp7jh",
        "service_dowp7jh",
        formData,
        "user_okJQC4Ix2ffCEfYcXM00A"
      )
      .then(() => {
        notifySuccess();
        setIsSubmitting(false);
      })
      .catch(() => {
        notifyError();
        setIsSubmitting(false);
      });
  };
  const notifySuccess = () =>
    toast("Your message has been sent!", {
      type: "success",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  const notifyError = () =>
    toast("Something went wrong!", {
      type: "error",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Kontakt - Adjustment Digital</title>
        <meta
          name="description"
          content="Imaš pitanje? Kontaktiraj nas putem kontakt forme ili na email office@adjustmentdigital.rs"
        />
        <link rel="canonical" href="https://www.adjustmentdigital.rs/kontakt" />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Kontakt
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 MREŽE
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form
                className="grid grid-cols-1 gap-6"
                action="#"
                method="post"
                onSubmit={handleSubmit}
              >
                <label className="block">
                  <Label>Puno Ime</Label>

                  <Input
                    placeholder="npr Petar Petrovic"
                    type="text"
                    className="mt-1"
                    name="name"
                    onChange={changeHandler}
                    value={formData.name}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Email adresa</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    name="email"
                    onChange={changeHandler}
                    value={formData.email}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Poruka</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    name="message"
                    onChange={handleTextArea}
                    value={formData.message}
                    required
                  />
                </label>
                <div>
                  <ButtonPrimary loading={isSubmitting} type="submit">
                    Send Message
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
