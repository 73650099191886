import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";

import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";

import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";

import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";

import PageHome2 from "containers/PageHome/PageHome2";

import SiteHeader from "containers/SiteHeader";
import PageService from "../containers/ServicePage/PageService";

import PageIzradaSajtova from "../containers/ServicePage/PageIzradaSajtova";
import PageMarketingUsluge from "../containers/ServicePage/PageMarketingUsluge";
import MarketingNaDrustvenim from "../containers/ServicePage/MarketingNaDrustvenim";
import PageSEOptimizacija from "../containers/ServicePage/PageSEOOptimizacija";
import PagePorfolio from "../containers/ServicePage/Portfolio";
import Modal from "../components/Modal/Modal";
import PageTikTok from "containers/ServicePage/PageTikTok";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome2 },
  { path: "/usluge", exact: true, component: PageService },
  { path: "/izrada-sajtova", exact: true, component: PageIzradaSajtova },

  { path: "/seo-optimizacija", exact: true, component: PageSEOptimizacija },

  {
    path: "/kompletne-marketing-usluge",
    exact: true,
    component: PageMarketingUsluge,
  },
  {
    path: "/marketing-na-drustvenim-mrezama",
    exact: true,
    component: MarketingNaDrustvenim,
  },
  {
    path: "/tiktok-usluge",
    exact: true,
    component: PageTikTok,
  },

  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },

  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },

  { path: "/kontakt", exact: true, component: PageContact },
  { path: "/onama", exact: true, component: PageAbout },
  { path: "/portfolio", exact: true, component: PagePorfolio },
  { path: "/subscription", component: PageSubcription },
  //
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Modal />
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
