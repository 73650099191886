import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { TaxonomyType } from 'data/types';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

export interface CardCategory5Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory5: FC<CardCategory5Props> = ({
  className = '',
  taxonomy,
}) => {
  const { desc, name, href = '/', thumbnail } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategory5 flex flex-col ${className}`}
      data-nc-id="CardCategory5"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          alt={name}
          className="object-contain w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 px-3 lg:min-h-[100px] text-center">
        <h3
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {name}
        </h3>
        <span
          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
          {desc}
        </span>
      </div>
      <ButtonPrimary
        className="bg-[#4F46E5] hover:bg-indigo-700  self-center my-3"
        // onClick={() => setModal({ isOpen: true })}
      >
        Zatraži Ponudu
      </ButtonPrimary>
    </Link>
  );
};

export default CardCategory5;
