import * as React from "react";
import { PricingItem } from "./PageSubcription";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import { useSetRecoilState } from "recoil";
import { modalState } from "../../atoms/modalAtom";

interface PricingItemProps {
  index: number;
  pricing: PricingItem;
}

const PricingItemComponent: React.FC<PricingItemProps> = ({
  index,
  pricing,
}) => {
  const setModal = useSetRecoilState(modalState);
  return (
    <div
      key={index}
      className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
        pricing.isPopular
          ? "border-primary-500"
          : "border-neutral-100 dark:border-neutral-700"
      }`}
    >
      {pricing.isPopular && (
        <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
          POPULARNO
        </span>
      )}
      <div className="mb-8">
        <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
          {pricing.name}
        </h3>
        <h2 className="text-5xl leading-none flex items-center">
          <span className="text-lg ml-1 font-normal text-neutral-500 mr-1">
            OD{" "}
          </span>
          <span>{pricing.pricing}</span>
          <span className="text-lg ml-1 font-normal text-neutral-500">
            {pricing.per}
          </span>
        </h2>
      </div>
      <nav className="space-y-4 mb-8">
        {pricing.features.map((item, index) => (
          <li className="flex items-center" key={index}>
            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
              <CheckIcon className="w-5 h-5" aria-hidden="true" />
            </span>
            <span className="text-neutral-700 dark:text-neutral-300">
              {item}
            </span>
          </li>
        ))}
        {pricing.negativeFeatures?.map((item, index) => (
          <li className="flex items-center" key={index}>
            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
              <XIcon className="w-5 h-5" aria-hidden="true" />
            </span>
            <span className="text-neutral-700 dark:text-neutral-300">
              {item}
            </span>
          </li>
        ))}
      </nav>
      <div className="flex flex-col mt-auto">
        {pricing.isPopular ? (
          <ButtonPrimary onClick={() => setModal({ isOpen: true })}>
            Zatraži ponudu
          </ButtonPrimary>
        ) : (
          <ButtonSecondary onClick={() => setModal({ isOpen: true })}>
            <span className="font-medium">Zatraži ponudu</span>
          </ButtonSecondary>
        )}
        <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3 text-center">
          {pricing.desc}
        </p>
      </div>
    </div>
  );
};

export default PricingItemComponent;
