import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionStatistic from "../PageAbout/SectionStatistic";
import SectionHeroService from "./SectionHeroService";
import SectionHero1Service from "./SectionHero1Service";
import SectionOurFeaturesService from "./SectionOurFeaturesService";

import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";
import animation from "../../images/animations/3-marketing-research-data.json";
import PricingItemComponent from "../PageSubcription/PricingItem";
import { PricingItem } from "../PageSubcription/PageSubcription";

export interface PageAboutProps {
  className?: string;
}
const pricing: PricingItem[] = [
  {
    isPopular: false,
    name: "PREZENTACIONI SAJTOVI",
    pricing: "350€",
    features: [
      "Do 5 stranica",
      "Podešavanje kontakt formi i mail servisa",
      "Osnovni SEO",
      "CMS sistem - Wordpress sajt",
      "Održavanje prva 3 meseca",
      "Optimizacija sajta na svim uređajima",
    ],
    negativeFeatures: [
      "Čist kod",
      "Moderan custom dizajn",
      "Gratis domen ( .rs / .com )",
      "Baza za login korisnika",
    ],
  },
  {
    isPopular: true,
    name: "PROFESIONALAN VEB SAJT",
    pricing: "700€",
    features: [
      "Do 5 stranica",
      "Podešavanje kontakt formi i mail servisa",
      "Osnovni SEO",
      "CMS sistem - Wordpress sajt",
      "Održavanje prva 3 meseca",
      "Optimizacija sajta na svim uređajima",
      "Čist kod",
      "Moderan custom dizajn",
      "Gratis domen ( .rs / .com )",
    ],
    negativeFeatures: ["Baza za login korisnika"],
  },
  {
    isPopular: false,
    name: "ONLINE PRODAVNICA",
    pricing: "900€",
    features: [
      "Do 5 stranica",
      "Podešavanje kontakt formi i mail servisa",
      "Osnovni SEO",
      "CMS sistem - Wordpress sajt",
      "Održavanje prva 3 meseca",
      "Optimizacija sajta na svim uređajima",
      "Čist kod",
      "Moderan custom dizajn",
      "Gratis domen ( .rs / .com )",
      "Baza za login korisnika",
    ],
  },
];

const PageIzradaSajtova: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Izrada Web Sajtova, Web Prodavnica - Već za 10 dana!</title>
        <meta
          name="description"
          content="Potreban ti je web sajt za tvoj biznis, ali želiš pouzdan i sajt koji se brzo učitava? Naš tim web dizajnera će ti dizajnirati sajt koji povećava prodaju!"
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.rs/izrada-sajtova"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Izrada"
          heading2="Sajtova"
          btnText=""
          subHeading="Želite savršenu veb prezentaciju za vaš biznis? Mi želimo da vam pomognemo u tome!"
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />
        <SectionHeroService
          json={animation}
          rightImg="../../slike/izrada-sajtova-benefiti.png"
          heading="🤔 Da li mi je potreban sajt?"
          btnText=""
          subHeading="Tvoj sajt predstavlja tvoj biznis na internetu, sigurni smo da želiš da se predstaviš u najboljem svetlu. Naš tim veb dizajnera će ti u  tome pomoći! U 2022. godini ne smeš dopusiti da ti sajt izgleda kao da je pravljen 2002. godine što možeš dobiti od dosta agencija na internetu po jako niskim cenama."
        />

        <SectionOurFeaturesService
          rightImg="../../../slike/izrada-sajtova-benefiti.png"
          title2="BENEFITI DOBROG SAJTA"
          btn="POVERENJE"
          text="Što je sajt profesionalniji, to je veće poverenje kupaca u tvoj biznis. Niko ne voli kada ode na sajt nekog brenda i vidi sajt koji je ružan i nefukncionalan, to odmah odaje utisak neprofesionalnosti."
          btn2="PRODAJA"
          btn3="PUBLIKA"
          subtitle="Veće poverenje kod kupaca"
          subtitle2="Veća stopa konverzije"
          subtitle3="Nalaženje nove publike"
          text2="Dokazana i testirana činjenica je da svaki sajt koji je dobro napravljen i optimizovan, ima veću prodaju. Sami smo to testirali na nekoliko biznisa, gde smo uspešno redizajnirali sajt i drastično povećali konverziju sa identičnim reklamama."
          text3="Posedovanjem sajta, imaš šansu da dopreš do veće publike. Kada budu nešto pretraživali, ukoliko ti je sajt dobro optimizovan, dobićeš više poseta, samim tim i nove kupce koji možda ne znaju za tebe. Ukoliko ti je potrebna optimizacija sajta, pogledaj ovu uslugu."
          title="Kako izrada sajta doprinosi mom biznisu?
"
        />
        <div className="w-screen flex flex-col justify-center mx-auto my-12 items-center   max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            💰 Izrada sajta cene
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            Cene koje su uklapaju u preduzeća raznih veličina
          </span>
        </div>
        <div
          className={`grid ${
            pricing && pricing.length > 1 ? "xl:grid-cols-3 " : "null"
          } gap-5 xl:gap-8`}
        >
          {pricing?.map((pricing, index) => (
            <PricingItemComponent pricing={pricing} index={index} />
          ))}
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor
            title="💸Da li se izrada sajta isplati?"
            text="Na izradu sajta moraš gledati kao na dugoročnu investiciju, a ne trošak, jer to i jeste investicija. Dobar sajt se radi jednom, a doprinosi vašem biznisu na duže staze. Tako da odgovor na postavljeno pitanje je i više nego očigledan: Da! Isplati se višestruko!
Zatraži ponudu izrade sajta
"
            btn="Zatraži ponudu izrade sajta"
          />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          className="!mb-6"
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj druge usluge"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
      </div>
    </div>
  );
};

export default PageIzradaSajtova;
