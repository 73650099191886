import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/marketing-na-drustvenim-mrezama",
    name: "Marketing na društvenim mrežama",
  },
  {
    id: ncNanoId(),
    href: "/tiktok-usluge",
    name: "TikTok usluge",
  },
  //
  { id: ncNanoId(), href: "/izrada-sajtova", name: "Izrada sajtova" },
  { id: ncNanoId(), href: "/seo-optimizacija", name: "SEO Optimizacija" },
  //
  {
    id: ncNanoId(),
    href: "/kompletne-marketing-usluge",
    name: "Kompletne marketing usluge",
  },
];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Pocetna",
  },
  {
    id: ncNanoId(),
    href: "",
    name: "Usluge",
    type: "dropdown",
    children: templatesChildrenMenus,
  },
  {
    id: ncNanoId(),
    href: "/onama",
    name: "O nama",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/blog",
  //   name: "Blog",
  // },

  {
    id: ncNanoId(),
    href: "/kontakt",
    name: "Kontakt",
  },

  {
    id: ncNanoId(),
    href: "/portfolio",
    name: "Portfolio",
  },
];
