import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "50+",
    subHeading: "Zadovoljnih klijenata našim reklamnim kampanjama\n",
  },
  {
    id: "2",
    heading: "1,000+",
    subHeading: "Uspešnih Facebook i Instagram marketing kampanja",
  },
  {
    id: "3",
    heading: "€50,000+",
    subHeading:
      "Profita ostvarenog klijentima uz pomoć marketinga na društvenim mrežama",
  },
];

export interface SectionStatisticProps {
  className?: string;
  title?: string;
  description?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({
  className = "",
  description,
  title,
}) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc={
          description
            ? description
            : "Mi smo mali tim, usko specijalizovanih ljudi za vaše potrebe. Svakog dana kreiramo vrhunski sadržaj i reklame na društvenim mrežama"
        }
      >
        {title ? title : " 🚀 Brze činjenice o nama"}
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;