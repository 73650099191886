import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionStatistic from "../PageAbout/SectionStatistic";
import SectionHeroService from "./SectionHeroService";
import SectionHero1Service from "./SectionHero1Service";
import SectionOurFeaturesService from "./SectionOurFeaturesService";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";
import animation from "../../images/animations/1.json";

export interface PageAboutProps {
  className?: string;
}

const PageMarketingUsluge: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>
          Kompletne Marketing Usluge - Google Oglašavanje, Društvene Mreže
        </title>
        <meta
          name="description"
          content="Bićemo tvoje istureno marketing odeljenje! Društvene mreže, Google oglasi, SEO, Email marketing i još mnogo toga, garantovano povećavamo prodaju tvom biznisu."
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.rs/kompletne-marketing-usluge"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 lg:space-y-28">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Kompletne"
          heading2="Marketing Usluge"
          btnText=""
          subHeading={`${'Prepusti nama ceo marketing i gledaj kako tvoj biznis raste. Bićemo tvoj "istureni" sektor za marketing koji će obavljati sve procese umesto tebe.'}`}
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-40 flex overflow-hidden z-0" />
        <SectionHeroService
          rightImg="../../../slike/marketing-usluge.png"
          json={animation}
          heading="📚 Šta će mi Marketing usluge?"
          btnText=""
          subHeading="Čak su i ptice na granama naučile koliko je marketing bitan u današnjem svetu društvenih mreža. Ukoliko misliš da samostalno možeš da izguraš ceo proces marketinga, samo napred! Mi smo se nažalost uverili da to nije moguće i da je bitnije da se posvetiš onome što najbolje radiš - svom biznisu. Tu smo da zajedno rastemo!"
        />
        <SectionHowItWork
          title="Kako izgleda taj proces?"
          subtitle="Koje su faze saradnje, kako sve funkcioniše?"
          data={[
            {
              id: 1,
              img: "../../../slike/istrazivanje-proces.png",
              imgDark: "../../../slike/istrazivanje-proces.png",
              title: "Napravimo plan",
              desc: "Na početku, naravno, pravimo detaljan marketing plan i spisak svih marketing aktivnosti.",
            },
            {
              id: 2,
              img: "../../../slike/planiranje-proces.png",
              imgDark: "../../../slike/planiranje-proces.png",
              title: "Radimo dogovoreno",
              desc: "Odmah krećemo sa realizacijom dogovorenog. Pravimo materijale, kampanje...",
            },
            {
              id: 3,
              img: "../../../slike/kreiranje-proces.png",
              imgDark: "../../../slike/kreiranje-proces.png",
              title: "Dobiješ rezultate",
              desc: "Logičan sled događaja: vidljivi rezultati, svi zadovoljni i nastavljamo dalje saradnju!",
            },
          ]}
        />
        <SectionOurFeaturesService
          rightImg="../../../slike/marketing-usluge.png"
          btn="DRUŠTVENE MREŽE
"
          text="Društvene mreže su naravno stub komunikacije sa potencijalnim kupcima. Dobijaš redovne objave na društvenim mrežama, kao i najbolje prodajne reklame!
"
          btn2="EMAIL MARKETING
"
          btn3="SEO"
          subtitle="Vođenje mreža i oglašavanje"
          subtitle2="Slanje prodajnih mejlova
"
          subtitle3="Optimizacija sajta
"
          text2="EMAIL Marketing je jedan do najbolji, ali možda i najpodcenjenijih marketing kanala. Kroz razne prodajne marketing mailove, povećaćemo prodaju, bez trošenja ikakvog budžeta, totalno besplatno!
"
          text3="Optimizacijom sajta za Google pretraživače (SEO) dobićeš dosta novih poseta i ljudi će te lako pronaći na Google. To je dugoročna strategija koja donosi neverovatne rezultate.
"
          title="Koje sve Marketing usluge dobijam?"
          title2="KOMPLETNE MARKETING USLUGE
"
        />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj druge usluge"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageMarketingUsluge;