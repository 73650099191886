import React, { FC } from "react";
import rightImgPng from "images/our-features.png";

import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";
import { PricingItem } from "../PageSubcription/PageSubcription";
import PricingItemComponent from "../PageSubcription/PricingItem";

export interface ReklamiranjeProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
  title?: string;
  title2?: string;
  subtitle?: string;
  naziv?: string;
  text?: string;
  text2?: string;
  text3?: string;
  text4?: string;
  pricing?: PricingItem[];
}

const Reklamiranje: FC<ReklamiranjeProps> = ({
  className = "lg:py-14",
  type = "type1",
  text,
  naziv,
  text2,
  text3,
  text4,
  title,
  subtitle,
  pricing,
}) => {
  return (
    <div>
      <div className={` mx-auto`}>
        <div className="w-full pb-3">
          <div className=" max-w-lg py-2 md:pt-28 mx-auto text-left md:max-w-none md:text-center">
            <h1 className="flex flex-wrap justify-items-start md:items-center md:justify-center text-5xl font-extrabold leading-10 tracking-tight text-left md:text-center sm:leading-none md:text-6xl lg:text-7xl ">
              <span className="inline mr-2  md:block"> {naziv}</span>
            </h1>
            <div className="mx-auto mt-2 text-gray-400  md:max-w-lg md:text-center lg:text-lg">
              {subtitle}
            </div>
          </div>
        </div>
        <BgGlassmorphism />
      </div>
      <div
        className={`nc-SectionOurFeatures relative gap-6 mt-14 md:mt-0 flex flex-col-reverse xs:flex-row items-center ${
          type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
        data-nc-id="SectionOurFeatures"
      >
        <div className="flex-grow">
          {/*<PageSubcription data={pricings} />*/}
          <div
            className={`grid ${
              pricing && pricing.length > 1 ? "xl:grid-cols-2 " : "null"
            } gap-5 xl:gap-8`}
          >
            {pricing?.map((pricing, index) => (
              <PricingItemComponent pricing={pricing} index={index} />
            ))}
          </div>
        </div>
        <div className={`max-w-xl text-sm`}>
          <h2 className="font-semibold text-4xl ">{title}</h2>

          <div
            className="space-y-4 mt-4
             text-[1rem] leading-6"
          >
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text}
            </p>
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text2}
            </p>
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text3}
            </p>
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text4}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reklamiranje;