import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { TaxonomyType } from 'data/types';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

export interface CardCategory4Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = '',
  taxonomy,
}) => {
  const { name, href = '/', thumbnail } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategory4 flex flex-col ${className}`}
      data-nc-id="CardCategory4"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-6 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          className="object-contain w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 px-2 truncate text-center">
        <h3
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {name}
        </h3>
        <ButtonPrimary
          href={href}
          className="bg-[#4F46E5] hover:bg-indigo-700 w-[80%] self-center my-3"
        >
          Pogledaj
        </ButtonPrimary>
      </div>
    </Link>
  );
};

export default CardCategory4;
