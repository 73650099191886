import * as React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Label from "../Label/Label";
import Input from "../../shared/Input/Input";
import Textarea from "../../shared/Textarea/Textarea";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { useRecoilState } from "recoil";
import { modalState } from "../../atoms/modalAtom";
import twFocusClass from "../../utils/twFocusClass";
import { XIcon } from "@heroicons/react/solid";
import Checkbox from "../../shared/Checkbox/Checkbox";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

interface ModalProps {}
// interface IFormState {
//   name: string;
//   lastName: string;
//   email: string;
//   message: string;
//   site: string;
//   instagram: string;
//   number: string;
//   instagramAndFacebook: boolean;
//   socialNetworks: boolean;
//   seo: boolean;
// }

const Modal: React.FC<ModalProps> = () => {
  const [{ isOpen }, setModal] = useRecoilState(modalState);
  const [loading, setLoading] = useState(false);
  // @ts-ignore
  //TODO - create change handler for inputs
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    message: "",
    site: "",
    instagram: "",
    instagramAndFacebook: false,
    socialNetworks: false,
    seo: false,
    izradaSajta: false,
    number: "",
  });
  const notifySuccess = () =>
    toast("Your message has been sent!", {
      type: "success",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  const notifyError = () =>
    toast("Something went wrong!", {
      type: "error",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCheckbox = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        "service_dowp7jh",
        "service_dowp7jh",
        formData,
        "user_okJQC4Ix2ffCEfYcXM00A"
      )
      .then(() => {
        notifySuccess();
        setModal({ isOpen: false });
        setLoading(false);
      })
      .catch(() => {
        notifyError();
        setLoading(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setModal({ isOpen: false })}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 h-screen w-full">
              <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100"
                    id="headlessui-dialog-title-70"
                  >
                    Zatraži Ponudu
                  </h3>
                  <span className="absolute left-3 top-3">
                    <button
                      className={
                        `w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700  ` +
                        twFocusClass()
                      }
                      onClick={() => setModal({ isOpen: false })}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" />
                    </button>
                  </span>
                </div>
                <div className="px-8 py-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <form
                    className="flex flex-col lg:grid lg:grid-cols-2 gap-6"
                    action="#"
                    method="post"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <label>
                      <Label>Url Sajta</Label>

                      <Input
                        placeholder="www.mojsajt.com"
                        type="text"
                        className="mt-1"
                        name="site"
                        value={formData.site}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      <Label>Instagram Profil Biznisa*</Label>

                      <Input
                        placeholder="Moj Instagram Profil"
                        type="text"
                        className="mt-1"
                        required={true}
                        name="instagram"
                        value={formData.instagram}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      <Label>Ime*</Label>

                      <Input
                        placeholder="Marko"
                        type="text"
                        className="mt-1"
                        required={true}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      <Label>Prezime*</Label>

                      <Input
                        placeholder="Markovic"
                        type="text"
                        className="mt-1"
                        required={true}
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      <Label>Broj Telefona*</Label>

                      <Input
                        placeholder="063 123 456"
                        type="text"
                        className="mt-1"
                        required={true}
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                      />
                    </label>
                    <label>
                      <Label>Tvoj Mail*</Label>

                      <Input
                        placeholder="tvojmail@gmail.com"
                        type="email"
                        className="mt-1"
                        required={true}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </label>
                    <label className="col-span-2">
                      <div>
                        <Label>Oblast</Label>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 mt-4 ">
                        <Checkbox
                          name="instagramAndFacebook"
                          label="Facebook I Instagram Reklamiranje"
                          onChange={handleCheckbox}
                        />
                        <Checkbox
                          name="socialNetworks"
                          label="Rast na Društvenim Mrežama"
                          onChange={handleCheckbox}
                        />
                        <Checkbox
                          name="seo"
                          onChange={handleCheckbox}
                          label="Search Engine Optimizacija(SEO)"
                        />{" "}
                        <Checkbox
                          name="izradaSajta"
                          onChange={handleCheckbox}
                          label="Izrada Sajta"
                        />
                      </div>
                    </label>
                    <label className="block col-span-2">
                      <Label>Poruka</Label>

                      <Textarea
                        className="mt-1"
                        rows={6}
                        name="message"
                        value={formData.message}
                        onChange={handleTextArea}
                        placeholder="Predstavi se"
                      />
                    </label>
                    <div>
                      <ButtonPrimary type="submit" loading={loading}>
                        Pošalji
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
