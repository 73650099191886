import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Lottie from "react-lottie-player";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
  styleType?: boolean;
  title?: string;
  title2?: string;
  btn?: string;
  btn2?: string;
  btn3?: string;
  subtitle?: string;
  subtitle2?: string;
  subtitle3?: string;
  text?: string;
  text2?: string;
  text3?: string;
  json?: object;
}

const SectionOurFeaturesService: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
  btn,
  text,
  btn2,
  btn3,
  subtitle,
  subtitle2,
  subtitle3,
  text2,
  text3,
  title,
  title2,
  styleType,
  json,
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        {json ? (
          <Lottie
            loop
            animationData={json}
            play
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <NcImage src={rightImg} />
        )}
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          {title2 ? title2 : "Benefiti"}
        </span>
        <h2 className="font-semibold text-4xl mt-5">{title}</h2>

        {!styleType ? (
          <ul className="space-y-10 mt-16">
            <li className="space-y-4">
              <Badge name={btn} />
              <span className="block text-xl font-semibold">{subtitle}</span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {text}
              </span>
            </li>
            <li className="space-y-4">
              <Badge color="green" name={btn2} />
              <span className="block text-xl font-semibold">{subtitle2}</span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {text2}
              </span>
            </li>
            <li className="space-y-4">
              <Badge color="red" name={btn3} />
              <span className="block text-xl font-semibold">{subtitle3}</span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {text3}
              </span>
            </li>
          </ul>
        ) : (
          <div className="space-y-10 mt-16">
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text}
            </p>
            <p className="space-y-4 block mt-5 text-neutral-500 dark:text-neutral-400">
              {text2}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionOurFeaturesService;
