import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title> O Nama - Adjustment Digital </title>
        <meta
          name="description"
          content=" Upoznajte naš tim! Adjustment Digital je mlada agencija sa dosta iskustva i bezbroj zadovoljnih klijenata. Saznajte više o nama."
        />
        <link rel="canonical" href="https://www.adjustmentdigital.rs/onama" />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg="../../slike/adjustment-digital-tim.png"
          heading="🧠 Šta znači Adjustment Digital?"
          btnText=""
          subHeading={`
            "Adjustment Digital je tvoj prijatelj ka postizanju digitalnih uspeha. Adjust znači - podešavati, prva dva slova AD su na engleskom - reklama. ADJ - predstavlja inicijale osnivača Andrić Đorđa. Tako dolazimo do Adjustment Digital, podešavanje (optimizovanje) tvojih reklama, ali i drugih digitalnih aktivnosti, za postzanje najboljih rezultata!"
          `}
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;