import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionStatistic from "../PageAbout/SectionStatistic";
import SectionHeroService from "./SectionHeroService";
import SectionHero1Service from "./SectionHero1Service";
import SectionOurFeaturesService from "./SectionOurFeaturesService";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import animation from "../../images/animations/2-business-growth.json";
import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";
import Reklamiranje from "./Reklamiranje";
import { PricingItem } from "../PageSubcription/PageSubcription";

export interface PageAboutProps {
  className?: string;
}

const MarketingNaDrustvenim: FC<PageAboutProps> = ({ className = "" }) => {
  const facebookPricing: PricingItem[] = [
    {
      isPopular: true,
      name: "OSNOVNI PAKET",
      pricing: "300€",
      per: "/mesečno",
      features: [
        "Podešavanje reklama kroz Ads Manager",
        "Podešavanje Facebook Pixela",
        "Priprema strategije i plana oglašavanja",
        "Izrada sadržaja / brifing drugom dizajneru / video editoru",
        "Istraživanje tržišta i konkurencije",
        "Pisanje prodajnih tekstova i scenarija za reklame",
        "Praćenje analitike i optimizacija reklama",
        "Nadzor i upravljanje budžetima za ogašavanje",
        "Retargeting kampanje",
      ],
      negativeFeatures: [
        "Vođenje društvenih mreža",
        "Kreiranje sadržaja za društvene mreže",
        "Plan aktivnosti za društvene mreže",
      ],
      desc: "Dodatni budžet za oglašavanje nije uključen u cenu",
    },
  ];
  const instagramPricing: PricingItem[] = [
    {
      isPopular: true,
      name: "OSNOVNI PAKET",
      pricing: "250€",
      per: "/mesečno",
      features: [
        "Podešavanje reklama ",
        "Priprema strategije i plana oglašavanja",
        "Izrada sadržaja po IG standardima",
        "Istraživanje tržišta i konkurencije",
        "Pisanje prodajnih tekstova i scenaria za reklame",
        "Praćenje analitike i optimizacija reklama",
        "Nadzor i upravljanje budžetima za ogašavanje",
        "Retargeting kampanje",
      ],
      negativeFeatures: [
        "Vođenje društvenih mreža",
        "Kreiranje sadržaja za društvene mreže",
        "Plan aktivnosti za društvene mreže",
      ],
      desc: "Dodatni budžet za oglašavanje nije uključen u cenu",
    },
  ];
  const vodjenjePricing: PricingItem[] = [
    {
      isPopular: false,
      name: "Osnovi Paket",
      pricing: "250€",
      per: "/mesečno",
      features: [
        "Izrada mesečnog plana objava",
        "Do 8 objava za feed",
        "Do 15 story objava",
        "Određivanje ciljnih grupa",
        "Kreiranje do 3 reklama za objavljen sadržaj",
        "Do 2 društvene mreže",
        "Analiza proila i sadržaja",
      ],
      negativeFeatures: [
        "Izveštavanje na mesečnom nivou",
        "Istraživanje tržišta i konkurencije",
        "Praćenje rezultata u prodaji",
        "Odgovaranje na upite, poruke i komentare",
      ],
      desc: "Dodatni budžet za oglašavanje nije uključen u cenu",
    },
    {
      isPopular: true,
      name: "Napredni Paket",
      pricing: "350€",
      per: "/mesečno",
      features: [
        "Izrada mesečnog plana objava",
        "12+ objava za feed",
        "20+ story objava",
        "Određivanje ciljnih grupa",
        "Kreiranje do 5 reklama za objavljen sadržaj",
        "3+ društvene mreže",
        "Analiza proila i sadržaja",
        "Izveštavanje na mesečnom nivou",
        "Istraživanje tržišta i konkurencije",
        "Praćenje rezultata u prodaji",
        "Odgovaranje na upite, poruke i komentare",
      ],

      desc: "Dodatni budžet za oglašavanje nije uključen u cenu",
    },
  ];

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Marketing Na Društvenim Mrežama - Reklame, Vođenje, Cene</title>
        <meta
          name="description"
          content="Vođenje društvenih mreža i reklame nekada znaju da budu muktorpan posao, posebno ako se ne bavite time. Zato smo mi tu da to radimo umesto tebe!"
        />
        <link
          rel="canonical"
          href="https://www.adjustmentdigital.rs/marketing-na-drustvenim-mrezama"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container  relative space-y-16 ">
        <SectionHero1Service
          rightImg={rightImg}
          heading="Marketing na"
          heading2="Društvenim Mrežama"
          btnText=""
          subHeading="
Marketing na društvenim mrežama je ono što će vas izdvojiti u moru loših reklama i konkurencije. Zajedno ćemo napraviti profitabilne i efikasne reklame!
"
        />
        <BgGlassmorphism className="absolute inset-x-1 bottom-1/3 min-h-0 pl-40 py-2 flex overflow-hidden z-0" />
        <SectionHeroService
          className="lg:!-mt-24"
          json={animation}
          rightImg={rightImg}
          heading="📱 Zašto društvene mreže?"
          btnText=""
          subHeading="Sigurno ste svesni da gotovo ne postoji osoba preko 18 godina koja nema bar jednu društvenu mrežu. Društvene mreže su trenutno najefikasniji vid reklamiranja jer za manje novca možete prikazati reklamu grupi ljudi koji su spremni da kupe od vas ili postanu vaši korisnici."
        />
        <SectionHowItWork
          title="Kako izgleda ceo proces?"
          data={[
            {
              id: 1,
              img: "../../../slike/istrazivanje-proces.png",
              imgDark: "../../../slike/istrazivanje-proces.png",
              title: "Istraživanje",
              desc: "Upoznavanje sa ciljevima. Istraživanje konkurencije i tržišta.",
            },
            {
              id: 2,
              img: "../../../slike/planiranje-proces.png",
              imgDark: "../../../slike/planiranje-proces.png",
              title: "Planiranje",
              desc: "Definisanje detaljnog marketing plana, budžeta, zadataka i ciljeva.\n",
            },
            {
              id: 3,
              img: "../../../slike/kreiranje-proces.png",
              imgDark: "../../../slike/kreiranje-proces.png",
              title: "Kreiranje i testiranje",
              desc: "Pisanje prodajnog teksta, kreiranje reklama za dr. mreže i objavljivanje.",
            },
            {
              id: 4,
              img: "../../../slike/pracenje-rezultata-proces.png",
              imgDark: "../../../slike/pracenje-rezultata-proces.png",
              title: "Praćenje rezultata",
              desc: "Analiziranje i podešavanje kampanja za još bolje rezultate.",
            },
          ]}
        />
        <SectionOurFeaturesService
          rightImg="../../../slike/marketing-na-dr-mrezama-benefiti.png"
          btn="ZARADA"
          text="Marketing na društvenim mrežama će povećati prodaju, ali ono što je najbitnije je da će povratiti uloženo i ostvariti zaradu."
          btn2="BREND"
          btn3="ANALIZA"
          subtitle="Zagarantovan rast prodaje i profita"
          subtitle2="Širenje svesti i građenje brenda"
          subtitle3="Jeftina analiza tržišta"
          text2="Prikazivanje reklama većem broju ljudi, učiniće da se tvoj brend ureže u svest potrošaču. Tako ćeš postepeno graditi svoj brend."
          text3="Marketing kampanjama možeš vrlo lako videti koje proizvodi su vredni prodaje, a koji proizvodi ti samo troše budžet u prazno. To ti je jasna smernica za dalji rad."
          title="Marketing na društvenim mrežama - Šta dobijam time?"
          title2="BENEFITI MARKETINGA"
        />

        <Reklamiranje
          text="Nema jasnog cenovnika kada je budžet za Facebook reklame u pitanju. Koliko vi odlučite da uložite, toliko košta Facebook reklamiranje. Cena svakako zavisi od dosta različitih faktora kao što su: zemlja u kojoj se oglašavate, vrsta kampanje, zasićenost vaše niše, tj sa koliko se još biznisa takmičite."
          text2="Uglavnom nam je bitno da kampanja donese što više konverzija, tj. akcija koju želimo da potencijalni kupci urade nakon što vide naš oglas. Konverzija je svaka akcija koju primalac marketing poruke učini. Ona se uglavnom odnosi na prodaje, ali može biti sve što mi postavimo kao cilj. To može biti prijava na našu mailing listu, skidanje nekog prospekta, kataloga, pa čak i poseta neke određene strane. Sve što mi postavimo kao cilj kampanje je konverzija."
          text3="Nekada se konverzija desi odmah, a često je potrebno da kupac vidi nekoliko reklama pre nego što se odluči da donese akciju. Tada na scenu stupaju retargeting reklame, koje imaju mogućnost da ciljaju sve koji su na primer: posetili naš sajt, a nisu kupili ili videli 75% naše reklame, a nisu kupili i tako dalje. To je vreme da im pustimo još jednu reklamu koja će im otkloniti sumnje i naterati ih da kupe od nas."
          text4="Cene naših usluga Facebook reklamiranja takođe variraju od vaših potreba, mogućnosti i veličine vaše firme, ali vam garantujemo da je ulaganje u marketing investicija, a ne trošak!"
          title="💰FACEBOOK REKLAMIRANJE CENA"
          type="type2"
          naziv="Facebook reklamiranje"
          subtitle="Za sada neprevaziđeni način reklamiranja jeste Facebook reklamiranje. Kažemo neprevaziđen zato što je Facebook i dalje jedna od vodećih mreža i jedna od najisplativijih za reklamiranje. Takođe Facebook je i dalje primarna društvena mreža za većinu korisnika"
          pricing={facebookPricing}
        />
        <Reklamiranje
          text="
Instagram reklamiranje je jeftinije, ali ne i isplativije, takođe nudi vam mnogo manje mogućnosti, gotovo nikakve mogućnosti osim biranje okvirne ciljne grupe, da li želite posete sajtu ili profilu i tako neke osnovne stvari. Takozvani Instagram Boost tj. Promovisanje objava služi da malo “pogura” objavu koju želite, lajkove na toj slici, generalno vaš Instagram profil, ali retko je najbolja opcija za prodaju.
"
          text2="Instagram reklamiranje je odlično za sve biznise koji nemaju svoju prodavnicu kao što smo već napomenuli. "
          text3="Ukoliko nemate svoju online prodavnicu, Instagram može poslužiti kao vaša online prodavnica i reklamiranje na Instagramu može poslužiti za povećanje prodaje."
          text4="Cene naših usluga Facebook reklamiranja takođe variraju od vaših potreba, mogućnosti i veličine vaše firme, ali vam garantujemo da je ulaganje u marketing investicija, a ne trošak!"
          title="💰INSTAGRAM  REKLAMIRANJE CENA"
          type="type2"
          naziv="Instagram reklamiranje"
          subtitle="Instagram reklamiranje se kod ljudi koji se ozbiljno bave marketingom, vrlo retko radi odvojeno od Facebook reklamiranja. Pošto su godinama u nazad pod okriljem iste kompanije, najbolje je puštati reklame kroz Facebook Ads Manager koje će se pojavljivati i na Instagramu i na Facebook. Svakako Instagram je prvo na šta se mali preduzetnici oslone ukoliko nemaju svoj sajt i što im često donese neke dobre rezultate za manji budžet."
          pricing={instagramPricing}
        />
        <Reklamiranje
          text="
Vođenje profila na društvenim mrežama često zna da bude jako naporno, pogotovo ukoliko vam to nije primarni posao. To često oduzima dosta vremena koje možete mnogo produktivnije potrošiti. Najbolja opcija je da to prespustite profesionalcima. Time štedite vreme, dobijate profil koji će biti u skladu sa vašim ciljevima i tako privući potencijalne kupce i korisnike. Društvene mreže su danas nešto bez čega nijedan biznis ne može da prođe, zato što je razvoj brenda usko vezan i za prisustvo na svim mrežama.
"
          title="#️⃣Vođenje društvenih mreža"
          type="type2"
          pricing={vodjenjePricing}
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor
            title="💸Da li se to meni isplati?"
            text="Kao i svakom ko vodi biznis, bitno je da nešto vraća novac na uloženo. Upravo je to glavni adut zbog kojeg klijenti nastavljaju saradnju sa nama. Gledamo da svaku reklamnu kampanju optimizujemo da donosi što veći profit na uloženo. Trudićemo se da što manje novca potrošimo, a da vi što pre vidite rezultate. "
            btn="Zatraži ponudu"
          />
        </div>
        <SectionStatistic />
        <SectionSliderNewCategories
          categoryCardType="card4"
          itemPerRow={4}
          heading="Pogledaj ostale usluge"
          subHeading="Popularne usluge koje ti mogu pomoći u razvoju biznisa"
          sliderStyle="style2"
          uniqueClassName="PageHome_s2"
        />
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default MarketingNaDrustvenim;